import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SunsetBackground from "../components/sunsetbackground"; // Not ideal, but putting styling here for the ::before & ::after

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <SunsetBackground/>
  </Layout>
)

export default IndexPage
