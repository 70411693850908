import React from "react"
    
const Cloud = (props) => (
    // clouds generously inspired by
    // https://codepen.io/ocanodiego/pen/wWNqYG?editors=1100
    <path style={{fill: props.fill}} 
                        d="M3954.56352,1727.18239c0,30.24334-23.92859,54.76155-53.44894,54.76155H3634.20682c-28.55212,0-52.33727-20.90321-57.57173-48.58846a61.73888,61.73888,0,0,1-1.07157-11.49127c0-27.69543,18.29038-51.00987,43.17048-57.96616a61.39486,61.39486,0,0,1-.44067-7.35308c0-32.86657,26.00506-59.5086,58.08583-59.5086a56.74656,56.74656,0,0,1,17.73959,2.82838c19.61557-27.81859,51.54948-45.92081,87.61278-45.92081,59.65814,0,108.01957,49.54608,108.01957,110.66885q0,4.612-.36382,9.135a52.42871,52.42871,0,0,1,11.72729-1.32359C3930.63493,1672.42418,3954.56352,1696.939,3954.56352,1727.18239Z" 
                        transform="translate(-3575.56352 -1553.94394)"/>
)

export default Cloud