import React from "react"
import posed from "react-pose";
import "./sunset.css"

const Image = posed.img({
    "underTheSea": { 
        rotate: 0, 
        y: 20,
        x: 0,
        opacity: 0,
        transition: { 
            duration: 1000,
            ease: 'easeInOut', 
        }
    },
    "0": { 
        rotate: 3, 
        y: -4,
        x: 5,
        opacity: 1,
        transition: ({ animationDur }) => ({ 
            duration: animationDur,
            ease: 'linear', 
        })
    },
    "1": {
        rotate: 0, 
        y: 0,
        x: 0,
        opacity: 1,
        transition: ({ animationDur }) => ({ 
            duration: animationDur,
            ease: 'linear', 
        })
    },
    "2": {
        rotate: -3, 
        y: -3,
        x: -5,
        opacity: 1,
        transition: ({ animationDur }) => ({ 
            duration: animationDur,
            ease: 'linear', 
        })
    },
    "3": {
        rotate: -1, 
        y: 0,
        x: -10,
        opacity: 1,
        transition: ({ animationDur }) => ({ 
            duration: animationDur,
            ease: 'linear', 
        })
    }
});
    
export class LogoWave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            direction: -1,
            rotPose: props.initRotPose,
        };
    }

    componentDidMount() {
        setInterval(() => {
            let curRotPose = parseInt(this.state.rotPose)
            let dir = this.state.direction
            let nextPose = curRotPose + dir 
            if (nextPose < 0 || nextPose > 3) {
                dir = -1 * dir 
                nextPose = curRotPose + dir
            }
            this.setState({direction: dir, rotPose: `${nextPose}`});
        }, this.props.animationDur)
    }

    render() {
        const boolToState = {
            true: {},
            false: {display: "none"}
        }
        const widthStyle = {width: this.props.width}

        return (
            <div className={this.props.containerClass} style={{...styles.container, ...widthStyle}}> 
                <div className="wave" style={{...widthStyle, ...boolToState[this.props.ssrDone]}}></div>
                <div className="waveCover" style={{...widthStyle, ...boolToState[this.props.ssrDone]}}></div>
                <a href={this.props.link} onClick={this.props.onClick} target="_blank" rel="noopener noreferrer">
                    <Image src={this.props.src} 
                        height={0.8 * this.props.width} 
                        width={0.8 * this.props.height} 
                        animationDur={this.props.animationDur}
                        style={styles.logoImg} 
                        alt={this.props.alt}
                        initialPose={"underTheSea"}
                        pose={this.state.rotPose}
                    />
                </a>
            </div>
        );
    }
}

const styles = {
    container: {
        position: "relative",
        display: "inline-block",
        textAlign: "center",
    }, 
    logoImg: {
        marginBottom: 0,
        zIndex: 0,
        cursor: "pointer",
        opacity: 0,
    }
}

export default LogoWave