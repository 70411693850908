import React from "react"
import Cloud from "./cloud";
import Emoji from 'a11y-react-emoji'
import { FaGithub, FaLinkedin, FaTwitter, FaGoodreads } from 'react-icons/fa';
import FadeRotateImg from "./faderotate";
import LogoWave from "./logowave";
import sun from "../images/sun.png"
import { createMedia } from "@artsy/fresnel"

// import logos - must be SQUARE
import ka from "../images/logos/ka.png"
import msft from "../images/logos/msft.png"
import nines from "../images/logos/nines.png"
import fw from "../images/logos/freewill.png"

import "./sunset.css"
import "./styles.css"

const { MediaContextProvider, Media  } = createMedia({
    breakpoints: {
      sm: 0,
      md: 768,
      lg: 1024,
      xl: 1192
    },
});

export class SunsetBackground extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            itsRainbowTime: false,
            infoSection: "about",
            isMounted: false,
        }
    }

    hoverOnMe = () => {
        this.setState({itsRainbowTime: true})
    }

    hoverOffMe = () => {
        this.setState({itsRainbowTime: false})
    }

    showInfoSection = (id) => {
        this.setState({infoSection: id})
    }

    componentDidMount() {
        this.setState({isMounted: true})
    }

    render() {
        const kaLink = "https://khanacademy.org"
        const kaAlt = "Khan Academy logo"
        const ninesLink = "https://www.ninesai.com"
        const ninesAlt = "Nines AI logo"
        const msftLink = "https://microsoft.com"
        const msftAlt = "Microsoft logo"
        const freewillLink = "https://freewill.com"
        const freewillAlt = "FreeWill logo"
        const rainbowAboutHeader =
            this.state.itsRainbowTime ? (
                <h3 style={styles.aboutHeader}
                    onMouseEnter={this.hoverOnMe}
                    onMouseLeave={this.hoverOffMe}>
                        <span style={styles.rainbowR}>a</span>
                        <span style={styles.rainbowO}>b</span>
                        <span style={styles.rainbowY}>o</span>
                        <span style={styles.rainbowG}>u</span>
                        <span style={styles.rainbowB}>t</span>
                        {' '}
                        <span style={styles.rainbowI}>m</span>
                        <span style={styles.rainbowV}>e</span>
                        {' '}
                        <Emoji symbol="🌈" label="rainbow" />
                </h3>
            ) : (
                <h3 style={styles.aboutHeader}
                    onMouseEnter={this.hoverOnMe}
                    onMouseLeave={this.hoverOffMe}>
                        about me
                </h3>
            );
        const rainbowMatthew =
            this.state.itsRainbowTime ? (
                <h1 style={styles.textBottomLine}
                    onMouseEnter={this.hoverOnMe}
                    onMouseLeave={this.hoverOffMe}
                    onClick={()=>{this.showInfoSection("about")}}>
                        <span style={styles.rainbowR}>m</span>
                        <span style={styles.rainbowO}>a</span>
                        <span style={styles.rainbowY}>t</span>
                        <span style={styles.rainbowG}>t</span>
                        <span style={styles.rainbowB}>h</span>
                        <span style={styles.rainbowI}>e</span>
                        <span style={styles.rainbowV}>w</span>
                </h1>
            ) : (
                <h1 style={styles.textBottomLine}
                    onMouseEnter={this.hoverOnMe}
                    onMouseLeave={this.hoverOffMe}
                    onClick={()=>{this.showInfoSection("about")}}>
                        matthew
                </h1>
            );
        // helps control which about sections are visible in the cloud
        const boolToState = {
            true: "block",
            false: "none"
        }

        return (
            <div style={styles.wrapper}>
                <div className="responsiveCloud1">
                    <svg viewBox="0 0 379 228"><title>cloudy boi 1</title>
                        <Cloud fill="#f7fcff"/>
                    </svg>
                </div>
                <div className="responsiveCloud2">
                    <svg viewBox="0 0 379 228"><title>cloudy boi 2</title>
                        <Cloud fill="#f7fcff"/>
                    </svg>
                </div>
                <div className="responsiveCloud3">
                    <svg viewBox="0 0 379 228"><title>cloudy boi 3</title>
                        <Cloud fill="#f7fcff"/>
                    </svg>
                </div>
                <div className="responsiveCloud4">
                    <svg viewBox="0 0 379 228"><title>cloudy boi 4</title>
                        <Cloud fill="#f7fcff"/>
                    </svg>
                </div>
                <div style={styles.textWrapper}>
                    <div style={styles.helloTextWrapper}>
                        <h2 style={styles.textTopLine}>it's-a me,</h2>
                        {
                            rainbowMatthew
                        }
                        {/* Unintuitively, the image goes inside the text wrapper, so it
                            can at least be somewhat text-aligned */}
                        <FadeRotateImg src={sun} style={styles.sun}/>
                    </div>
                    <div style={styles.aboutTextWrapper}>
                        {
                            this.state.infoSection === "about" ? rainbowAboutHeader : ''
                        }
                        <MediaContextProvider>
                            <Media lessThan="md">
                                <div style={styles.cloudyBackgroundMobile}>
                                    <svg viewBox="0 0 379 228"><title>cloudy boi 4</title>
                                        <Cloud fill="#fff"/>
                                    </svg>
                                </div>
                            </Media>
                            <Media greaterThanOrEqual="md">
                                <div style={styles.cloudyBackground}>
                                    <svg viewBox="0 0 379 228"><title>cloudy boi 4</title>
                                        <Cloud fill="#fff"/>
                                    </svg>
                                </div>
                            </Media>
                        </MediaContextProvider>
                        {/* LITTLE INFO SECTIONS FOR DESKTOP */}
                        <span style={{display: boolToState[this.state.infoSection === "about"]}}>
                            <p style={styles.aboutText}>
                                i'm a second-year graduate student in Computer Science at Princeton University. i enjoy building and learning with kind people.
                            </p>
                        </span>
                        <span style={{display: boolToState[this.state.infoSection === "ka"]}}>
                            <p style={styles.infoText}>
                                at <a href={kaLink} className="infoLink" target="_blank" rel="noopener noreferrer">Khan Academy</a>,
                                i was a software engineering intern supporting the new {' '}
                                <a href="https://www.khanacademy.org/district" className="infoLink" target="_blank" rel="noopener noreferrer">districts product</a>.
                                i helped translate roster data from 300k+ students onto Khan Academy's platform using Dataflow, Cloud Datastore, GraphQL, and ReactJS.
                            </p>
                        </span>
                        <span style={{display: boolToState[this.state.infoSection === "nines"]}}>
                            <p style={styles.infoText}>
                                i was a machine learning engineering intern at{' '}
                                <a href={ninesLink} className="infoLink" target="_blank" rel="noopener noreferrer">Nines</a>,
                                {' '}an Accel-backed startup that applies machine learning to the clinical setting.
                                in addition to supporting ML infrastructure, i wrote a paper on transfer learning in NLP for
                                medical AI!
                            </p>
                        </span>
                        <span style={{display: boolToState[this.state.infoSection === "msft"]}}>
                            <p style={styles.infoText}>
                                i interned in <a href={msftLink} className="infoLink" target="_blank" rel="noopener noreferrer">Microsoft's</a>
                                {' '}AI+Research division, using modern AI and natural language understanding techniques
                                to refine their question-answering technology, which serves 40MM+ queries/day.
                            </p>
                        </span>
                        <span style={{display: boolToState[this.state.infoSection === "freewill"]}}>
                            <p style={styles.infoText}>
                                my first-ever software engineering internship was at{' '}
                                <a href={freewillLink} className="infoLink" target="_blank" rel="noopener noreferrer">FreeWill</a>,
                                {' '}a <a href="https://bcorporation.net/" className="infoLink" target="_blank" rel="noopener noreferrer">B-corp</a>
                                {' '}that helps folks donate to charity in their wills for free, with the goal of moving
                                {' '}$1T into the nonprofit sector. (catch my work screenshotted in the
                                {' '}<a href="https://www.nytimes.com/2018/09/07/your-money/online-wills.html"
                                        className="infoLink"
                                        target="_blank"
                                        rel="noopener noreferrer">
                                            new york times
                                    </a>
                                !)
                            </p>
                        </span>
                        <p>
                            <a
                                href="https://github.com/sunnymatt/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="aboutLink"
                                style={styles.aboutLink}>
                                <FaGithub  />
                            </a>
                            <a
                                href="https://linkedin.com/in/matthewds"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="aboutLink"
                                style={styles.aboutLink}>
                                <FaLinkedin  />
                            </a>
                            <a
                                href="https://twitter.com/matthewdsun"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="aboutLink"
                                style={styles.aboutLink}>
                                <FaTwitter  />
                            </a>
                            <a
                                href=" https://www.goodreads.com/sunnymatt"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="aboutLink"
                                style={styles.aboutLink}>
                                <FaGoodreads  />
                            </a>
                        </p>
                    </div>
                </div>
                <div className="ocean">
                    <MediaContextProvider>
                        <Media greaterThanOrEqual="md">
                            <div style={styles.logosContainer}>
                                <LogoWave
                                    containerClass={"ka"}
                                    alt={kaAlt}
                                    onClick={()=>{this.showInfoSection("ka")}}
                                    src={ka}
                                    height={100}
                                    width={100}
                                    animationDur={650}
                                    initRotPose={"2"}
                                    ssrDone={this.state.isMounted} />
                                <LogoWave
                                    containerClass={"nines"}
                                    alt={ninesAlt}
                                    onClick={()=>{this.showInfoSection("nines")}}
                                    src={nines}
                                    height={100}
                                    width={100}
                                    animationDur={600}
                                    initRotPose={"1"}
                                    ssrDone={this.state.isMounted} />
                                <LogoWave
                                    containerClass={"msft"}
                                    alt={msftAlt}
                                    onClick={()=>{this.showInfoSection("msft")}}
                                    src={msft}
                                    height={100}
                                    width={100}
                                    animationDur={580}
                                    initRotPose={"3"}
                                    ssrDone={this.state.isMounted} />
                                <LogoWave
                                    containerClass={"freewill"}
                                    alt={freewillAlt}
                                    onClick={()=>{this.showInfoSection("freewill")}}
                                    src={fw}
                                    height={100}
                                    width={100}
                                    animationDur={620}
                                    initRotPose={"0"}
                                    ssrDone={this.state.isMounted} />
                            </div>
                        </Media>
                        <Media lessThan="md">
                            <div style={styles.logosContainer}>
                                <div style={styles.mobileLogoContainer}>
                                    <LogoWave
                                        src={ka}
                                        alt={kaAlt}
                                        link={kaLink}
                                        height={100}
                                        width={100}
                                        animationDur={650}
                                        initRotPose={"2"}
                                        ssrDone={this.state.isMounted} />
                                </div>
                                <div style={styles.mobileLogoContainer}>
                                    <LogoWave
                                        src={msft}
                                        alt={msftAlt}
                                        link={msftLink}
                                        height={100}
                                        width={100}
                                        animationDur={600}
                                        initRotPose={"1"}
                                        ssrDone={this.state.isMounted} />
                                </div>
                                <div style={styles.mobileLogoContainer}>
                                    <LogoWave
                                        src={nines}
                                        alt={ninesAlt}
                                        link={ninesLink}
                                        height={100}
                                        width={100}
                                        animationDur={580}
                                        initRotPose={"3"}
                                        ssrDone={this.state.isMounted} />
                                </div>
                                <div style={styles.mobileLogoContainer}>
                                    <LogoWave
                                        src={fw}
                                        alt={freewillAlt}
                                        link={freewillLink}
                                        height={100}
                                        width={100}
                                        animationDur={620}
                                        initRotPose={"0"}
                                        ssrDone={this.state.isMounted} />
                                </div>
                            </div>
                        </Media>
                    </MediaContextProvider>
                    <div className="beachWave"></div>
                </div>
                <div className="beach">
                </div>
            </div>
        );
    }
}

const styles = {
    wrapper: {
        position: "relative",
        backgroundColor: "#e0f2ff",
        // for mysterious reasons, zIndex has to be 0, otherwise
        // all the links aren't clickable 🤔
        zIndex: 0,
    },
    sun: {
        height: "300px",
        width: "300px",
        position: "absolute",
        top: "20px",
        marginLeft: "-50px",
        zIndex: -2, // make sure it gets painted behind the others!
        // TODO(matthew): which of these properties actually matters..?
        opacity: 0,
        transform: "translateZ(0)",
        transformStyle: "preserve-3d",
        WebkitTransformStyle: "preserve-3d",
        MozTransformStyle: "preserve-3d",
        backfaceVisibility: "hidden",
        WebkitBackfaceVisibility: "hidden",
        MozBackfaceVisibility: "hidden",
    },
    textTopLine: {
        marginBottom: 0,
    },
    textBottomLine: {
        display: "inline-block",
        cursor: "pointer",
    },
    textWrapper: {
        maxWidth: "960px",
        margin: "0 auto",
        paddingLeft: "40px",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
    },
    helloTextWrapper: {
        paddingTop: "160px",
    },
    aboutTextWrapper: {
        padding: "25px 40px 30px 40px",
        maxWidth: "400px",
        position: "relative",
    },
    aboutHeader: {
        marginBottom: "10px",
    },
    aboutText: {
        marginBottom: "10px",
    },
    infoText: {
        marginTop: "-4px", // TODO(matthew): this is a manual value to keep the about links in place
        marginBottom: "0px",
        marginRight: "15px",
        fontSize: "0.9em",
    },
    logosContainer: {
        maxWidth: "960px",
        margin: "0 auto",
        paddingTop: "50px",
    },
    mobileLogoContainer: {
        textAlign: "center",
        paddingBottom: "30px",
        display: "block",
    },
    rainbowR: {
        color: "#FF0900",
    },
    rainbowO: {
        color: "#FF7F00",
    },
    rainbowY: {
        color: "#F2BA0F",
    },
    rainbowG: {
        color: "#00F11D",
    },
    rainbowB: {
        color: "#34bbe6",
    },
    rainbowI: {
        color: "#0079FF",
    },
    rainbowV: {
        color: "#A800FF",
    },
    cloudyBackground: {
        width: "120%",
        left: "-50px",
        top: "-70px",
        position: "absolute",
        zIndex: "-1",
    },
    cloudyBackgroundMobile: {
        width: "140%",
        left: "-90px",
        top: "-25px",
        position: "absolute",
        zIndex: "-1",
    }
}

export default SunsetBackground