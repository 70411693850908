import React from "react"
import posed from "react-pose"

const Image = 
    posed.img({
        visible: { 
            opacity: 1,
            y: 0,
            rotate: 45,
            transition: {
                duration: 3000,
            } 
        },
        invisible: { 
            opacity: 0, 
            y: 50 
        }
    });
    
const FadeRotate = (props) => {
    return <Image {...props} initialPose="invisible" pose="visible"/>
}

export default FadeRotate